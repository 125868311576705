const shortestAngle = (a0, a1, max = Math.PI * 2) => {
    const da = (a1 - a0) % max;
    return 2 * da % max - da;
}
const wrapValue = (value, max) => {
    while (value < 0) value += max;
    return value % max;
}

const ratio = Math.sqrt(2);
const hoverSpread = 1;
const hoverScale = 2;

const state = {
    zoomed: false,
    hovered: null,
    idx: 0
}

const ringContainer = document.querySelector('.ring-container');
const imgs = [...document.querySelectorAll('img')];
const introduction = document.querySelector('.introduction');
const captions = [...document.querySelectorAll('.caption')];

let margin;
let gap;
let zoomedImageCenter = [0, 0];
let zoomedOutRadius = 0;
let zoomedOutCenter = [0, 0];
let imageLargeSize = [0, 0];
const onResize = () => {
    document.body.classList.toggle('zoomed', state.zoomed);
    if (window.innerWidth < 768) {
        margin = 16;
        gap = 16;
    } else {
        margin = 30;
        gap = 30;
    }
    const ringRect = ringContainer.getBoundingClientRect();
    zoomedOutCenter = [ringRect.left + ringRect.width / 2, ringRect.top + ringRect.height / 2];
    zoomedOutRadius = Math.min(ringRect.width, ringRect.height) / 2
    let maxCaptionHeight = Math.max(...captions.map(el => el.offsetHeight));
    let maxImageSize = [
        window.innerWidth - gap * 2,
        window.innerHeight - (margin * 2 + maxCaptionHeight)
    ];
    const scale = Math.min(maxImageSize[0], maxImageSize[1] / ratio);
    imageLargeSize = [scale, scale * ratio]
    zoomedImageCenter = [margin + maxImageSize[0] / 2, margin * 2 + maxImageSize[1] / 2];
    imgs.forEach(img => {
        img.style.width = imageLargeSize[0] + 'px';
        img.style.height = imageLargeSize[1] + 'px'
    })
    update();
}

const cssTranslate = v => `translate(${v[0]}px, ${v[1]}px)`;
const cssRotate = a => `rotate(${a}rad)`;
const cssScale = s => `scale(${s},${s})`;

const update = () => {
    document.body.classList.toggle('zoomed', state.zoomed);
    introduction.classList.toggle('disabled', state.zoomed || state.hovered !== null);
    const angleStep = Math.PI * 2 / imgs.length;
    const innerRadius = ((imageLargeSize[0] + gap) / 2) / Math.tan(angleStep / 2);
    const centerRadius = innerRadius + imageLargeSize[1] / 2;
    const outerRadius = innerRadius + Math.sqrt((imageLargeSize[0] / 2) ** 2 + imageLargeSize[1] ** 2);
    // const zoomedOutRadius = Math.min(window.innerWidth - margin * 2, window.innerHeight - margin * 2) / 2;
    const scale = state.zoomed ? 1 : zoomedOutRadius / outerRadius;
    const center = state.zoomed
        ? [zoomedImageCenter[0], zoomedImageCenter[1] + centerRadius]
        : zoomedOutCenter
    imgs.forEach((img, i) => {
        const resolution = state.zoomed && Math.abs(shortestAngle(i, state.idx, imgs.length) <= 1)
            ? 1
            : scale;
        let imageScale = 1;
        if (!state.zoomed && state.hovered !== null) {
            const d = Math.abs(shortestAngle(i, state.hovered, imgs.length));
            const t = Math.max(hoverSpread - d, 0) / hoverSpread;
            imageScale = 1 + (hoverScale - 1) * t;
        }
        const transform = [
            cssTranslate([-imageLargeSize[0] / 2, -imageLargeSize[1] / 2]),
            cssTranslate(center),
            cssRotate((i - (state.idx || 0)) * angleStep),
            cssScale(scale),
            cssTranslate([0, -centerRadius]),
            cssScale(imageScale)
        ];
        img.style.transform = transform.join(' ');
        img.style.zIndex = imgs.length - Math.abs(shortestAngle(i, state.hovered || 0, imgs.length))
        const showCaption = (state.zoomed && wrapValue(state.idx, imgs.length) === i) || (!state.zoomed && state.hovered !== null && wrapValue(state.hovered, imgs.length) === i)
        captions[i].classList.toggle('disabled', !showCaption);
        img.sizes = imageLargeSize[0] * resolution + 'px';
        if (!img.srcset) img.srcset = img.dataset.srcset;
        img.style.display = 'block';
    });
}

imgs.forEach((img, i) => {
    img.addEventListener('click', e => {
        e.stopPropagation();
        state.zoomed = true;
        state.idx += shortestAngle(state.idx, i, imgs.length);
        update();
    })
    if (!('ontouchstart' in window)) {
        img.addEventListener('mouseenter', () => {
            state.hovered = i;
            update();
        })
        img.addEventListener('mouseleave', () => {
            state.hovered = null;
            update();
        })
    }
})

document.querySelector('.arrow--left').addEventListener('click', e => {
    e.stopPropagation();
    state.idx -= 1;
    update();
})
document.querySelector('.arrow--right').addEventListener('click', e => {
    e.stopPropagation();
    state.idx += 1;
    update();
})

document.body.addEventListener('click', () => {
    state.zoomed = false;
    update();
})

window.addEventListener('scroll', () => {
    ringContainer.style.opacity = window.pageYOffset > 0 ? 0 : 1;
})


onResize();
window.addEventListener('resize', onResize);

const introductionShort = introduction.querySelector('.introduction__short');
const introductionLong = introduction.querySelector('.introduction__long');
introduction.querySelector('.read-more').addEventListener('click', () => {
    introductionShort.style.display = 'none';
    introductionLong.style.display = 'block';
})
introduction.querySelector('.read-less').addEventListener('click', () => {
    introductionShort.style.display = 'block';
    introductionLong.style.display = 'none';
})